<script lang="ts" setup>
defineProps<{
  modalClass?: string
  overlayClass?: string

  transitionName?: string

  /** Disables auto open on wrapper click */
  disableAutoOpen?: boolean

  /** Disables auto close when modal is clicked */
  disableModalClose?: boolean
}>()

const active = defineModel({
  default: false
})

/** Toggles state if no value is provided */
const setActive = (state = !active.value) => active.value = state
</script>

<template>
  <div class="relative" @click="disableAutoOpen || setActive(true)">
    <slot v-bind="{ setActive }" />

    <Transition :name="transitionName || 'fade'">
      <div v-if="active" class="relative z-999">
        <div class="fixed inset-0" :class="overlayClass" @click.stop="setActive(false)" />
        <div class="p-3 w-200px grid absolute right-0 rounded-6px bg-shade-light elevation-regular-3" :class="modalClass" @click.stop="disableModalClose || setActive(false)">
          <slot name="content" v-bind="{ setActive }" />
        </div>
      </div>
    </Transition>
  </div>
</template>
